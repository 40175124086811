.about-page {
  background-color: #E8E8EC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1rem);
  color: #282c34;
  min-height: 100vh;
  height: 100%;
}
.about-page-title{
  margin-top: 15px;
}

.color-link {
  color: #282c34;
}

.about-page-content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  margin: 15px;
  margin-top: 5px;
  flex-direction: row;
}

.linkedin-shot{
  height: 400px;
  width: 400px;
  display: inline-block;
}

.about-page-bio-short-title {
  font-size: calc(5px + 1.5rem);
  text-align: center;
}

.left{
  color: #282c34;
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  color: #282c34;
}

.left-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-links > a{
  color: inherit;
}

.right{
  flex-grow: 1;
  background-color: #E8E8EC;
  padding: 0px 20px 0px 30px;
  /*display: flex;*/
  font-size: 1.5rem;
}

.right > p{
  margin: 7px 0px;
}

@media only screen and (max-width: 1000px){
  .about-page-content{
    flex-direction: column;
  }
  .linkedin-shot{
    align-self: center;
  }
  .left {
    align-self: center;
  }
}
