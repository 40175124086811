.computer-science-home-page {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E8E8EC;
  height: 100vh;
}

.math-link {
  color: inherit;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.center-square{
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.square-line{
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
