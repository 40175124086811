.project-euler-home-page {
  padding: 0px 50px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E8E8EC;
  height: 100%;
  min-height: 100vh;
}

.problem-links{
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
}

.problem-link{
  color: inherit;
  margin-bottom: 50px;
  width: 25%;
  text-align: center;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
.actor-selector {
  width: 400px;
  color: #282c34;
  position: absolute;
  top: 100px;
}


.project-euler-solution-page {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  color: #E8E8EC;
  min-height: 100vh;
}

.project-euler-solution-page a {
  color: #E8E8EC;
}

.project-euler-solution-title{
  margin-top: 15px;
  font-size: 32px;
  text-align: center;
  color: #E8E8EC;
}

.project-euler-solution-subtitle{
  margin-top: 15px;
  font-size: 24px;
  text-align: center;
  color: #E8E8EC;
}

.project-euler-need-to-understand{
  font-size: 24px;
  margin: 15px 30px;
  color: #E8E8EC;
}

.project-euler-need-to-understand-first-paragraph{
  margin-top: 0px;
}

.i-understand-button{
  background-color: #E8E8EC; /* Green */
  border: none;
  color: #282c34;
  align-self: center;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.i-understand-button-container{
  display: flex;
  justify-content: center;
}
