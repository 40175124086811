.yelp-help-container {
  padding: 0px 50px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E8E8EC;
  height: 100%;
  min-height: 100vh;
}

.yelp-help-title{
  margin-top: 15px;
  font-size: 32px;
  text-align: center;
  color: #E8E8EC;
}


.yelp-help-container iframe {
  height: 472px;
  width: 840px;
}
